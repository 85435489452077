@use './global.module.scss' as global;

.signup_form {
    width: 880px;
    height: 405px;
    left: 460px;
    top: 60px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: global.$font-family;
}

.enable_btn {
    background-color: global.$enabled-button-color;
}

.disable_btn {
    background-color: global.$disabled-button-color;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .log_image {
       width: 300px;
    }

    .content_img {
        text-align: center;
    }

    .content_data {
        text-align: center;
        margin-right: 90px;
    }

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}