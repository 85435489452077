@use './global.module.scss' as global;

.footer {
    padding: 40px  50px !important;
    background-color: global.$footer-primary-color !important;
}

.footer > * p {
    color: #FFF !important;
    font-size: global.$font-size-sm-4 !important;
}


.footer_content {
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    position: 'relative' !important;
    padding-top: '50px' !important;
    color: #FFF !important;
    bottom: 0;
    width: '100%' !important;
}
            