@use './global.module.scss' as global;


* {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing:  border-box;
}

header {
    background-color:  global.$primary-color !important;
    box-shadow: none !important;
    color: global.$text-color !important;
}

.center_image {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

nav > * a {
    all: unset !important;
}

.aside {
    height: 100vh;
}
